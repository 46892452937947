<template>
  <div>
    <b-card
      title="Kullanım Kılavuzu"
    >
      <b-card-text>Aşağıdaki bağlantılara tıklayarak kılavuzlara erişebilirsiniz.</b-card-text>
      <b-list-group>
        <b-list-group-item
          href="https://inll.app/Medusa2/media/guides/sifir-satis-kullanim-kilavuzu.PDF"
          class="d-flex justify-content-between align-items-center"
          target="_blank"
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            Sıfır Satış ve Aksesuar
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="https://inll.app/Medusa2/media/guides/servis-kullanim-klavuzu.pdf"
          class="d-flex justify-content-between align-items-center"
          target="_blank"
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            Servis
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="#"
          class="d-flex justify-content-between align-items-center"
          disabled
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            İkinci El Satış
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="https://inll.app/Medusa2/media/guides/musteri-iliskileri-kullanim-kilavuzu.pdf"
          class="d-flex justify-content-between align-items-center"
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            Müşteri İlişkileri
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="#"
          class="d-flex justify-content-between align-items-center"
          disabled
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            Yedek Parça
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="https://inll.app/Medusa2/media/guides/sigorta-kullanim-kilavuzu.pdf"
          class="d-flex justify-content-between align-items-center"
          target="_blank"
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            Sigorta
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="#"
          class="d-flex justify-content-between align-items-center"
          disabled
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            Satın Alma
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="#"
          class="d-flex justify-content-between align-items-center"
          disabled
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            Gayrimenkul
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem, BCardText,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BCardText,
  },
}
</script>
